import { Typography } from "@material-ui/core";
import { Link } from "gatsby";
import * as React from "react";
import Layout from "../layouts/Layout";

const NotFound = () => {
  return (
    <Layout title="Not Found">
      <div
        style={{
          marginTop: 64,
          minHeight: 520,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          style={{ margin: "36px 0", textAlign: "center" }}
        >
          Whoops! The page you are looking for is not here.
        </Typography>
        <Typography
          variant="h5"
          style={{ margin: "24px 0", textAlign: "center" }}
        >
          Go back to the <Link to="/">home page</Link>.
        </Typography>
      </div>
    </Layout>
  );
};

export default NotFound;
